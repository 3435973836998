<template>
    <a :href="url" class="group flex h-min justify-center items-center z-[1]">
        <img :src="require(`@/assets/images/redirects/${image_name}`)"
        class="object-contain w-[128px] h-min h-max-[128px] group-hover:scale-110 transition duration-[0.6s]"
        :class="[start ? 'scale-100 opacity-100' : 'scale-0 opacity-0']">
    </a>
</template>

<script>

export default {
    props: {
        url: String,
        image_name: String
    },

    data() {
        return {
            start: false
        }
    },

    mounted() {
        setTimeout(() => {
            this.start = true;
        }, 100);

        setTimeout(() => {
            this.$el.firstElementChild.classList.remove('duration-[0.6s]');
        }, 700);
    }
}

</script>
