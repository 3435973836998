<template>
    <div class="absolute flex w-full h-full justify-center items-center">
        <img src="@/assets/images/background/background.png"
        class="absolute scale-[100%] translate-y-[35px] transition duration-[0.75s]"
        :class="[start ? 'opacity-100' : 'opacity-0']">
    </div>

    <div class="flex flex-col w-full h-full justify-between items-center p-[55px]">
        <G_Row>
            <template v-for="redirect in $redirects.up()" :key="redirect.url">
                <G_Redirect :url="redirect.url" :image_name="redirect.image_name"/>
            </template>
        </G_Row>

        <G_Row>
            <template v-for="redirect in $redirects.down()" :key="redirect.url">
                <G_Redirect :url="redirect.url" :image_name="redirect.image_name"/>
            </template>
        </G_Row>
    </div>
</template>

<script>

export default {
    name: 'App',

    data() {
        return {
            start: false,
        }
    },

    mounted() {
        setTimeout(() => {
            this.start = true;
        }, 100);
    }
}

</script>

<style>

/*#region Variables*/

:root{
	--default-background-color: #000000;
}

/*#endregion*/

/*#region Responsive Design*/

@media (min-width: 0) {
    html {
        font-size: 1px;
    }
}

@media (min-width: 620px) {
    html {
        font-size: 3px;
    }
}

@media (min-width: 768px) {
    html {
        font-size: 5px;
    }
}

@media (min-width: 1024px) {
    html {
        font-size: 7px;
    }
}

@media (min-width: 1280px) {
    html {
        font-size: 10px;
    }
}

@media (min-width: 1536px) {
    html {
        font-size: 13px;
    }
}

/*#endregion*/

/*#region Base*/

html {
    overflow-x: hidden;
    overflow-y: hidden;
}

html, body, #app{
    display: flex;
    flex-direction: column;

    max-width: 100%;
    height: 100%;

    transition: none;

    background-color: var(--default-background-color);
}

header, body{
    margin: 0;
}

div, span {
    -webkit-user-drag: none;
}

img, button, input, a {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

/*#endregion*/

</style>
