import { createApp } from 'vue'
import App from './App.vue'

import '@/plugins/app/_themes/tailwind.css'

import redirects from '@/plugins/app/_scripts/redirects'

const global = {
    install(app) {
        app.config.globalProperties.$redirects = redirects;
    }
}

// App
const app = createApp(App);

// Global Components
import Components from '@/plugins/app/_scripts/components'

Components.all().forEach(function (item) {
    app.component(item.name, item.component);
});

app
.use(global)
.mount('#app');
