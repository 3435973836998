import G_Row from '@/plugins/app/_components/g-row'
import G_Redirect from '@/plugins/app/_components/g-redirect'

export default class
{
    static all()
    {
        return [
            { name: 'G_Row', component: G_Row },
            { name: 'G_Redirect', component: G_Redirect },
        ];
    }
}
